import cs from 'classnames'
import React from 'react'

import s from './container.module.css'

const Container = ({children, className}) => {
  return <div className={cs(s.root, className)}>{children}</div>
}

export default Container
