import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBuilding, faEnvelope, faMap, faPhone} from '@fortawesome/free-solid-svg-icons'

import s from './footer.module.css'

export const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={s.footerWrapper}>
        <div className={s.col}>
          <img
            className={s.logoWhite}
            src='https://itify.s3.eu-north-1.amazonaws.com/assets/img/logowhite.svg'
          />
          <p className={s.aboutText}>
            Itify AS is a Norwegian digital consultancy, operating in the intersection of business
            and tech. We're the continuation of Impact Consult that has operated since 1997 on the
            Norwegian and European market. We deliver full stack cloud development, solution
            architecture and other digital consulting.
          </p>
        </div>
        <div className={s.col}>
          <h3>Contact info</h3>
          <div className={s.contactItem}>
            <FontAwesomeIcon icon={faMap} /> Høgtunveien 8, 1370 Asker (Norway)
          </div>
          <div className={s.contactItem}>
            <FontAwesomeIcon icon={faPhone} /> (+47) 4828 5555
          </div>
          <div className={s.contactItem}>
            <FontAwesomeIcon icon={faBuilding} /> NO 924 031 115 MVA
          </div>
          <div className={s.contactItem}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href='mailto:&#112;&#111;&#115;&#116;&#064;&#105;&#116;&#105;&#102;&#121;&#046;&#110;&#111;'>
              &#112;&#111;&#115;&#116;&#064;&#105;&#116;&#105;&#102;&#121;&#046;&#110;&#111;
            </a>
          </div>
        </div>
      </div>
      <div className={s.siteInfo}>&copy; {new Date().getFullYear()} Itify AS</div>
    </footer>
  )
}

export default Footer
