import {Link} from 'gatsby'
import React from 'react'

import styles from './header.module.css'

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to='/'>
          <img
            src='https://pertineovid.s3-eu-west-1.amazonaws.com/logo.svg'
            style={{height: '40px'}}
            alt={siteTitle}
          />
        </Link>
      </div>
    </div>
  </div>
)

export default Header
